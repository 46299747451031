/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
const r = {
  statsWorker: () => import("../../smartMapping/statistics/support/statsWorker.js"),
  geometryEngineWorker: () => import("../../geometry/geometryEngineWorker.js"),
  CSVSourceWorker: () => import("../../layers/graphics/sources/support/CSVSourceWorker.js"),
  EdgeProcessingWorker: () => import("../../views/3d/webgl-engine/lib/edgeRendering/EdgeProcessingWorker.js"),
  ElevationSamplerWorker: () => import("../../geometry/support/meshUtils/ElevationSamplerWorker.js"),
  FeatureServiceSnappingSourceWorker: () => import("../../views/interactive/snapping/featureSources/featureServiceSource/FeatureServiceSnappingSourceWorker.js"),
  GeoJSONSourceWorker: () => import("../../layers/graphics/sources/geojson/GeoJSONSourceWorker.js"),
  LercWorker: () => import("../../layers/support/LercWorker.js"),
  MemorySourceWorker: () => import("../../layers/graphics/sources/support/MemorySourceWorker.js"),
  PBFDecoderWorker: () => import("../../views/3d/support/PBFDecoderWorker.js"),
  FeaturePipelineWorker: () => import("../../views/2d/layers/features/FeaturePipelineWorker.js"),
  PointCloudWorker: () => import("../../views/3d/layers/PointCloudWorker.js"),
  RasterWorker: () => import("../../layers/support/RasterWorker.js"),
  SceneLayerSnappingSourceWorker: () => import("../../views/interactive/snapping/featureSources/sceneLayerSource/SceneLayerSnappingSourceWorker.js"),
  SceneLayerWorker: () => import("../../views/3d/layers/SceneLayerWorker.js"),
  WFSSourceWorker: () => import("../../layers/graphics/sources/WFSSourceWorker.js"),
  WorkerTileHandler: () => import("../../views/2d/engine/vectorTiles/WorkerTileHandler.js"),
  Lyr3DWorker: () => import("../../views/3d/layers/Lyr3DWorker.js")
};
export { r as registry };