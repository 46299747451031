/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import has from "../has.js";
import { isAbortError as t } from "../promiseUtils.js";
const r = "worker:port-closed";
var e;
!function (t) {
  t[t.HANDSHAKE = 0] = "HANDSHAKE", t[t.OPEN = 1] = "OPEN", t[t.OPENED = 2] = "OPENED", t[t.RESPONSE = 3] = "RESPONSE", t[t.INVOKE = 4] = "INVOKE", t[t.ABORT = 5] = "ABORT", t[t.CLOSE = 6] = "CLOSE", t[t.OPEN_PORT = 7] = "OPEN_PORT", t[t.ON = 8] = "ON";
}(e || (e = {}));
let n = 0;
function s() {
  return n++;
}
function o(t) {
  return t && "object" == typeof t && ("result" in t || "transferList" in t);
}
function i(t) {
  return t ? "string" == typeof t ? JSON.stringify({
    name: "message",
    message: t
  }) : t.toJSON ? JSON.stringify(t) : JSON.stringify({
    name: t.name,
    message: t.message,
    details: t.details || {
      stack: t.stack
    }
  }) : null;
}
function a(t, r, n, s) {
  if (r.type === e.OPEN_PORT) return void t.postMessage(r, [r.port]);
  if (r.type !== e.INVOKE && r.type !== e.RESPONSE) return void t.postMessage(r);
  let f;
  if (o(n) ? (f = u(n.transferList), r.data = n.result) : (f = u(s), r.data = n), f) {
    if (has("ff")) for (const n of f) if ("byteLength" in n && n.byteLength > 267386880) {
      const n = "Worker call with large ArrayBuffer would crash Firefox";
      switch (r.type) {
        case e.INVOKE:
          throw n;
        case e.RESPONSE:
          return void a(t, {
            type: e.RESPONSE,
            jobId: r.jobId,
            error: i(n)
          });
      }
    }
    t.postMessage(r, f);
  } else t.postMessage(r);
}
function f(t) {
  if (!t) return null;
  const r = t.data;
  return r ? "string" == typeof r ? JSON.parse(r) : r : null;
}
function u(t) {
  if (!t?.length) return null;
  if (has("esri-workers-arraybuffer-transfer")) return t;
  const r = t.filter(t => !c(t));
  return r.length ? r : null;
}
function c(t) {
  return t instanceof ArrayBuffer || "ArrayBuffer" === t?.constructor?.name;
}
async function O(e) {
  try {
    return await e;
  } catch (n) {
    const e = n?.name === r;
    if (!(t(n) || e)) throw n;
    return;
  }
}
export { e as MessageType, O as ignoreConnectionErrors, o as isTransferrableResult, s as newJobId, r as portClosedErrorName, a as postMessage, f as receiveMessage, i as toInvokeError };