/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
const n = new FinalizationRegistry(n => {
  n.close();
});
function e(e, i) {
  n.register(e, i, i);
}
function i(e) {
  n.unregister(e);
}
export { e as register, i as unregister };