/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { handlesGroup as e } from "../handleUtils.js";
import "../has.js";
import { isPromiseLike as t, createAbortError as s, createResolver as i, throwIfAborted as n } from "../promiseUtils.js";
import o from "../Queue.js";
import { unregister as r } from "./connectionRegistry.js";
import { createInvokeProxy as l } from "./InvokeHandler.js";
import h from "./RemoteClient.js";
class c {
  constructor() {
    this._inUseClients = new Array(), this._clients = new Array(), this._clientPromises = new Array(), this._ongoingJobsQueue = new o();
  }
  destroy() {
    this.close();
  }
  get closed() {
    return !this._clients?.length;
  }
  open(e, s) {
    return new Promise((i, o) => {
      let r = !0;
      const l = e => {
        n(s.signal), r && (r = !1, e());
      };
      this._clients.length = e.length, this._clientPromises.length = e.length, this._inUseClients.length = e.length;
      for (let n = 0; n < e.length; ++n) {
        const r = e[n];
        t(r) ? this._clientPromises[n] = r.then(e => (this._clients[n] = new h(e, s, this._ongoingJobsQueue), l(i), this._clients[n]), () => (l(o), null)) : (this._clients[n] = new h(r, s, this._ongoingJobsQueue), this._clientPromises[n] = Promise.resolve(this._clients[n]), l(i));
      }
    });
  }
  broadcast(e, t, s) {
    const i = new Array(this._clientPromises.length);
    for (let n = 0; n < this._clientPromises.length; ++n) {
      const o = this._clientPromises[n];
      i[n] = o.then(i => i?.invoke(e, t, s));
    }
    return i;
  }
  close() {
    let e;
    for (; e = this._ongoingJobsQueue.pop();) e.resolver.reject(s(`Worker closing, aborting job calling '${e.methodName}'`));
    for (const t of this._clientPromises) t.then(e => e?.close());
    this._clients.length = 0, this._clientPromises.length = 0, this._inUseClients.length = 0, r(this);
  }
  invoke(e, t, s) {
    return this.apply(e, [t], s);
  }
  apply(e, t, s) {
    const n = i();
    this._ongoingJobsQueue.push({
      methodName: e,
      data: t,
      invokeOptions: s,
      resolver: n
    });
    for (let i = 0; i < this._clientPromises.length; i++) {
      const e = this._clients[i];
      e ? e.jobAdded() : this._clientPromises[i].then(e => e?.jobAdded());
    }
    return n.promise;
  }
  createInvokeProxy(e) {
    return l(this, e);
  }
  on(t, s) {
    return Promise.all(this._clientPromises).then(() => e(this._clients.map(e => e.on(t, s))));
  }
  openPorts() {
    return new Promise(e => {
      const t = new Array(this._clientPromises.length);
      let s = t.length;
      for (let i = 0; i < this._clientPromises.length; ++i) {
        this._clientPromises[i].then(n => {
          n && (t[i] = n.openPort()), 0 == --s && e(t);
        });
      }
    });
  }
  get test() {}
}
export { c as default };